import PersonalizationInput from "../components/PersonalizationInput";
import PersonalizationCardImage from "../components/PersonalizationCardImage";
import PersonalizationInfoCard from "../components/PersonalizationInfoCard";
import PersonalizationTextarea from "../components/PersonalizationTextarea";
import PersonalizationDivider from "../components/PersonalizationDivider";
import React, {useEffect, useState} from "react";
import PersonalizationCardTabPicker from "../components/PersonalizationCardTabPicker";
import PersonalizationButton from "../components/PersonalizationButton";
import {useTranslation} from "react-i18next";
import {restoreHealthMetric, updateSurveyPersonalization} from "../../../../../../core/axios/services";
import {addUser} from "../../../../../../redux/actions/user";
import {useDispatch} from "react-redux";

export default function PersonalizationHealthMetric({
                                                        user,
                                                        getError,
                                                        showGlobalSnackbar,
                                                        defaultBodyMetrics,
                                                        healthMetricOrder,
                                                        languages,
                                                        activeLanguage,
                                                        setActiveLanguage,
                                                        surveySettingsFormData,
                                                        setSurveySettingsFormData,
                                                        allSurveySettingsFormData,
                                                        setAllSurveySettingsFormData,
}) {
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    const [defaultBodyMetric, setDefaultBodyMetric] = useState();

    function getCurrentlyEditingMetric(){
        return surveySettingsFormData?.bodyMetrics?.find(item => item.order === healthMetricOrder);
    }

    useEffect(() => {
        setDefaultBodyMetric(
            defaultBodyMetrics
                ?.find(item => item.id === activeLanguage?.id)?.bodyMetrics
                ?.find(item => item.order === healthMetricOrder)
        );
    },[activeLanguage]);


    const updateBodyMetric = (property, value) => {
        // let currentFormData = {...surveySettingsFormData};
        //
        // currentFormData.bodyMetrics = currentFormData?.bodyMetrics?.map((item) => {
        //     if (item?.order === healthMetricOrder) {
        //         return {
        //             ...item,
        //             [property]: value,
        //         }
        //     }
        //     return item;
        // });
        //
        // setSurveySettingsFormData(currentFormData);

        // Update all survey settings that match the current language
        const updatedSurveySettings = allSurveySettingsFormData.map((survey) => {
            if (survey.language?.id === surveySettingsFormData?.language?.id) {
                return {
                    ...survey,
                    bodyMetrics: survey.bodyMetrics?.map((item) =>
                        item?.order === healthMetricOrder
                            ? { ...item, [property]: value }
                            : item
                    ),
                };
            }
            return survey;
        });

        setAllSurveySettingsFormData(updatedSurveySettings);
    };


    const [showRestorePopup, setShowRestorePopup] = useState(false);
    const [isReverting, setIsReverting] = useState(false);


    async function handleRestoreHealthMetric() {
        setIsReverting(true);
        try {
            let response = await restoreHealthMetric(healthMetricOrder,user);
            if (response?.status === 200) {
                showGlobalSnackbar(i18n.t("saveAndSyncMessage"));
                dispatch(addUser(response?.data?.data));

                setIsReverting(false);
                setShowRestorePopup(false);
                showGlobalSnackbar(i18n.t("saveAndSyncMessage"));
                console.log('NEW USER: ',response?.data?.data)
            }
        } catch (error) {
            console.log('error', error);
        }
        setIsReverting(false);
    }

    function getErrorMessage(property){
        return getError('surveySettings',`bodyMetrics.${healthMetricOrder - 1}.${property}`);
    }

    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: '20px'
    }}>
        <PersonalizationCardTabPicker
            tabs={languages}
            activeTab={activeLanguage}
            setActiveTab={setActiveLanguage}
        />

        <PersonalizationInfoCard
            text={'Editing any health metric will archive the current data and create a new set of statistics. You can make changes in any language, but the statistics will always be reset.'}
        />

        <PersonalizationCardImage src={'/assets/survey_personalization_screens/hm.png'} alt={'Welcome Screen'}/>

        <PersonalizationInput
            title={'Title'}
            value={getCurrentlyEditingMetric()?.title?.toUpperCase()}
            setValue={(value) => updateBodyMetric('title', value?.toUpperCase())}
            errorMessage={getErrorMessage('title')}
        />

        <PersonalizationTextarea
            title={'Question/Statement'}
            value={getCurrentlyEditingMetric()?.question}
                setValue={(value) => updateBodyMetric('question', value)}
            errorMessage={getErrorMessage('question')}
        />

        <div style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: '20px',
        }}>
            <PersonalizationInput
                title={'Low Scale'}
                value={getCurrentlyEditingMetric()?.lowScaleName}
                setValue={(value) => updateBodyMetric('lowScaleName', value)}
                errorMessage={getErrorMessage('lowScaleName')}
            />
            <PersonalizationInput
                title={'High Scale'}
                value={getCurrentlyEditingMetric()?.highScaleName}
                setValue={(value) => updateBodyMetric('highScaleName', value)}
                errorMessage={getErrorMessage('highScaleName')}
            />
        </div>

        {
            !getCurrentlyEditingMetric()?.isDefault &&
            <PersonalizationButton
                text={'Restore statement & statistics'}
                mode={"small"}
                color={'white'}
                onClick={() => setShowRestorePopup(true)}
            />
        }

        {showRestorePopup && (
            <div className="ScreenOverlay">
                <div className="ScreenContent">
            <h2 style={{marginBottom: "40px"}}>
                {i18n.t("restoreMainTitle")}
            </h2>
                    <PersonalizationInfoCard
                        text={i18n.t("restoreDesc")}
                        backgroundColor={'white'}
                        boxShadow='0px 2px 4px rgba(97,132,157,0.2)'
                    />

                    <div style={{
                        width: '100%',
                        textAlign: "left",
                        margin: '20px 0',
                    }}>
                        <label style={{color: '#494949'}}>Title</label>
                        <p style={{
                            paddingBottom: '20px',
                            borderBottom: '1px solid lightgray',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>{defaultBodyMetric?.title}</p>

                        <label style={{color: '#494949'}}>Question/Statement</label>
                        <p style={{
                            paddingBottom: '20px',
                            borderBottom: '1px solid lightgray',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>{defaultBodyMetric?.question}</p>

                        <label style={{color: '#494949'}}>Low scale</label>
                        <p style={{
                            paddingBottom: '20px',
                            borderBottom: '1px solid lightgray',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>{defaultBodyMetric?.lowScaleName}</p>

                        <label style={{color: '#494949'}}>High scale</label>
                        <p style={{
                            paddingBottom: '20px',
                            borderBottom: '1px solid lightgray',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>{defaultBodyMetric?.highScaleName}</p>


                        <label style={{color: '#494949'}}>PDF title</label>
                        <p style={{
                            paddingBottom: '20px',
                            textAlign: 'left',
                            fontWeight: 'bold'
                        }}>{defaultBodyMetric?.pdfName}</p>
                    </div>


                    <h4 style={{marginBottom: '30px'}}>{i18n.t("restoreMessage")}</h4>


                    <button
                        style={{
                            border: 'none',
                            outline: 'none',
                            color: 'white',
                            minWidth: '300px',
                            padding: '7px 20px',
                            borderRadius: "23px",
                            boxShadow: "0px 2px 4px rgba(217, 217, 217, 1)",
                            backgroundColor: "rgba(97, 132, 157, 1)"
                        }}
                        onClick={() => handleRestoreHealthMetric()}
                        disabled={isReverting}
                    >
                        {isReverting ? "Saving..." : "Yes, apply"}
                    </button>


                    <div
                        style={{
                            cursor: 'pointer',
                            margin: '10px',
                            textDecoration: 'underline',
                        }}
                        onClick={() => setShowRestorePopup(false)}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        )}

        <PersonalizationDivider/>

        <PersonalizationCardImage src={'/assets/survey_personalization_screens/hm_pdf.png'} alt={'Welcome Screen'}/>

        <PersonalizationInput
            title={'Display on PDF as'}
            value={getCurrentlyEditingMetric()?.pdfName}
            setValue={(value) => updateBodyMetric('pdfName', value)}
            errorMessage={getErrorMessage('pdfName')}
        />
    </div>
}
