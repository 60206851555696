import PersonalizationDivider from "./PersonalizationDivider";

export default function PersonalizationCardTabPicker({tabs, activeTab, setActiveTab})
{
    const isActiveTab = (item) => activeTab?.value === item?.value;

    return <div>
        <div style={{
            marginLeft:'-20px',
            marginRight:'-20px',
            marginTop:'-20px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
        }}>
            {tabs?.map((item, index) => {
                return <div
                    key={index}
                    style={{
                        flexGrow: 1,
                        textAlign: "center",
                        cursor: "pointer",
                        position: "relative",
                        fontFamily: isActiveTab(item) ? 'HelveticaNeue-Bold' : 'HelveticaNeue-Medium',
                        color: isActiveTab(item) ? 'black' : '#9C9C9C',
                        fontSize: '20px',
                    }}
                    onClick={() => setActiveTab(item)}
                >
                    {item?.label}
                    <div style={{
                        width:"110%",
                        position: "absolute",
                        left: "-5%",
                        bottom:"-10px",
                        borderBottom: isActiveTab(item) ? '3px solid #61849D' : 'none',
                    }}> </div>
                </div>
            })}
        </div>

        <div style={{marginTop:'-8px'}}>
            <PersonalizationDivider/>
        </div>
    </div>

}
