import React, {useEffect, useState} from "react";
import "./SurveySettings.scss";
import { useTranslation } from "react-i18next";
import "react-tooltip/dist/react-tooltip.css";
import SurveyLanguageAndTypePicker from "../../../components/surveySettingsComponents/SurveyLanguageAndTypePicker";
import SurveyAdditionalSettings from "../../../components/surveySettingsComponents/SurveyAdditionalSettings";
import SurveySettingsPersonalizationScreens
  from "../../../components/surveySettingsComponents/SurveySettingsPersonalization/SurveySettingsPersonalizationScreens";
import SurveySettingsPersonalizationScreenPicker
  from "../../../components/surveySettingsComponents/SurveySettingsPersonalization/SurveySettingsPersonalizationScreenPicker";
import {useDispatch, useSelector} from "react-redux";
import {
    bodyMetricsDefauls,
    getMyUser,
    updateSurveyPersonalization,
    validateSurveyPersonalizationData
} from "../../../../core/axios/services";
import {addUser} from "../../../../redux/actions/user";
import Loader from "../../../components/Loader/Loader";
import {Oval} from "react-loader-spinner";
import PersonalizationInfoCard
    from "../../../components/surveySettingsComponents/SurveySettingsPersonalization/screens/components/PersonalizationInfoCard";
import {useNavigate} from "react-router-dom";

const SurveySettings = ({ showGlobalSnackbar }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const refreshLoggedUserData = async () => {
    //     return getMyUser(user)
    //         .then((res) => {
    //             dispatch(addUser(res.data.data));
    //         })
    //         .catch(() => {});
    // };
    //
    // useEffect(() => {
    //     refreshLoggedUserData();
    // }, []);

  const { i18n } = useTranslation();

  const user = useSelector((state) => state.user);

    const [defaultBodyMetrics, setDefaultBodyMetrics] = useState(null);

    useEffect(() => {
        if(!defaultBodyMetrics){
            const fetchData = async () => {
                try {
                    const res = await bodyMetricsDefauls(user);
                    setDefaultBodyMetrics(res?.data || null); // Safeguard against undefined data
                } catch (error) {
                    console.error("Failed to fetch body metrics:", error);
                    setDefaultBodyMetrics(null); // Optionally handle error by setting default value
                }
            };

            fetchData();
        }
    }, [user]); // If `user` changes, re-run the effect



    const getLanguages = () => {
        return (
            user?.user?.clinic?.languages?.map(item => ({
                id: item?.id,
                name: item?.name,
                code: item?.code,
                label: item?.name,
                value: item?.id,
            })) || []
        ).sort((a, b) => a.id - b.id);
    };

    const [languages, setLanguages] = useState([]);
    useEffect(() => {
        const langs = getLanguages();
        setLanguages(langs);
        if (langs.length > 0 && !language) {
            setLanguage(langs[0]);
        }
    }, [user]);



    const surveyTypes = user?.user?.clinic?.businessPlan?.id === 1 ?
      [
        {id: 1, name: "ChiroForm"},
      ]
      :
      [
        {id: 1, name: "ChiroForm"},
        {id: 2,name: "ChiroForm +"},
        {id: 3, name: "ChiroForm (CP)"},
      ];


    const [hasMadeAnyChanges, setHasMadeAnyChanges] = useState(false);

    const [surveySettingsFormData, setSurveySettingsFormData] = useState(user?.user?.clinic?.surveySettings);

    function setAllSurveySettingsFormData(data){
        setSurveySettingsFormData(data);
        setHasMadeAnyChanges(true);
    }

    const [surveySettingsIndex, setSurveySettingsIndex] = useState(null);

    const [healthConcernsFormData, setHealthConcernsFormData] = useState(user?.user?.clinic?.healthConcerns);
    const [healthConcernsIndex, setHealthConcernsIndex] = useState(null);

    const [lifeImpactsFormData, setLifeImpactsFormData] = useState(user?.user?.clinic?.lifeImpacts);
    const [lifeImpactsIndex, setLifeImpactsIndex] = useState(null);

    const [overallHealthsFormData, setOverallHealthsFormData] = useState(user?.user?.clinic?.overallHealths);
    const [overallHealthsIndex, setOverallHealthsIndex] = useState(null);

    const [stressesFormData, setStressesFormData] = useState(user?.user?.clinic?.stresses);
    const [stressesIndex, setStressesIndex] = useState(null);

    // Language and type
    const [language,setLanguage] = useState(languages?.[0]);
    const [type,setType] = useState(surveyTypes?.[0]?.id);

    useEffect(() => {
        setActiveScreen(null);
    },[type])

    const [activeScreen,setActiveScreen] = useState();

    async function setLanguageWithValidate(language) {
        let isDataValid = await validateCurrentlyEditingFormData();
        if (isDataValid) {
            setLanguage(language);
        }
    }
    async function setTypeWithValidate(type) {
        let isDataValid = await validateCurrentlyEditingFormData();
        if (isDataValid) {
            setType(type);
        }
    }
    async function setActiveScreenWithValidate(screen) {
        let isDataValid = await validateCurrentlyEditingFormData();
        if (isDataValid) {
            setActiveScreen(screen);
        }
    }

    // Additional settings
    const [sendResultsToPatients,setSendResultsToPatients] = useState(user?.user?.clinic?.sendResultsToPatients);
    const [sendResultsToClinic,setSendResultsToClinic] = useState(user?.user?.clinic?.sendResultsToClinic);
    const [clinicResultsEmail,setClinicResultsEmail] = useState(user?.user?.clinic?.clinicResultsEmail);
    const [reviewLink,setReviewLink] = useState(user?.user?.clinic?.googleLink);
    function handleSetReviewLink(link){
        setHasMadeAnyChanges(true);
        setReviewLink(link);
    }


    const [formDataErrors, setFormDataErrors] = useState();
    const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);



    const getCurrentlyEditingSurveySettings = () => {
        return surveySettingsFormData?.[surveySettingsIndex ?? -1];
    };

    const getCurrentlyEditingOverallHealths = () => {
        return overallHealthsFormData?.[overallHealthsIndex ?? -1];
    };

    const getCurrentlyEditingHealthConcerns = () => {
        return healthConcernsFormData?.[healthConcernsIndex ?? -1];
    };

    const getCurrentlyEditingLifeImpacts = () => {
        return lifeImpactsFormData?.[lifeImpactsIndex ?? -1];
    };

    const getCurrentlyEditingStresses = () => {
        return stressesFormData?.[stressesIndex ?? -1];
    };

    // Store indices for each dataset when language changes
    useEffect(() => {
        const updateIndices = () => {
            // Overall Healths Index
            setOverallHealthsIndex(overallHealthsFormData?.findIndex(
                item => item.language?.id === language?.id
            ));

            // Health Concerns Index
            setHealthConcernsIndex(healthConcernsFormData?.findIndex(
                item => item.language?.id === language?.id
            ));

            // Life Impacts Index
            setLifeImpactsIndex(lifeImpactsFormData?.findIndex(
                item => item.language?.id === language?.id
            ));

            // Stresses Index
            setStressesIndex(stressesFormData?.findIndex(
                item => item.language?.id === language?.id
            ));
        };

        updateIndices();
    }, [languages, language]);  // Dependency array to trigger the effect when `language` changes

    // Store indices for each dataset when language changes
    useEffect(() => {
        const updateIndices = () => {
            // Survey Settings Index
            setSurveySettingsIndex(surveySettingsFormData?.findIndex(
                item => item.language?.id === language?.id && item.type === type
            ));
        };

        updateIndices();
    }, [languages, language, type]);  // Dependency array to trigger the effect when `language` nad type changes

    function updateFormDataState(setStateFunction, updates, additionalCheck = () => true) {
        setHasMadeAnyChanges(true);
        setStateFunction(prevState =>
            prevState.map(item => {
                // Check if the item matches the current language and optionally type
                if (item.language?.id === language?.id && additionalCheck(item)) {
                    // Return a new object with updated properties
                    return {
                        ...item,
                        ...updates,
                    };
                }
                // Return the item unchanged
                return item;
            })
        );

        // compareAndLogChanges();
    }
    // Usage for different form data states

    function setStateForCurrentlyEditingSurveySettings(updates) {
        updateFormDataState(setSurveySettingsFormData, updates, item => item.type === type);
    }

    function setStateForCurrentlyEditingOverallHealths(updates) {
        updateFormDataState(setOverallHealthsFormData, updates);
    }

    function setStateForCurrentlyEditingHealthConcerns(updates) {
        updateFormDataState(setHealthConcernsFormData, updates);
    }

    function setStateForCurrentlyEditingLifeImpacts(updates) {
        updateFormDataState(setLifeImpactsFormData, updates);
    }

    function setStateForCurrentlyEditingStresses(updates) {
        updateFormDataState(setStressesFormData, updates);
    }



    function handleCopyLink(){
        let text = process.env.REACT_APP_SURVEYLINK + "/survey/" + getCurrentlyEditingSurveySettings()?.surveyLink
        // navigator.clipboard.writeText();

        if (navigator.clipboard && window.isSecureContext) {
            // Use Clipboard API if available and secure context
            navigator.clipboard.writeText(text).then(() => {
                console.log("Text copied successfully!");
            }).catch(err => {
                console.error("Clipboard API failed", err);
            });
        } else {
            // Fallback for older browsers and Safari
            const textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position = "fixed";  // Prevent scrolling
            textArea.style.opacity = "0";  // Hide the element
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand("copy");
                console.log("Text copied using execCommand");
            } catch (err) {
                console.error("Fallback copy failed", err);
            }
            document.body.removeChild(textArea);
        }

        showGlobalSnackbar(i18n.t("linkCopied"));
    }


    async function handleUpdateSurveySettings() {
        setIsUpdatingSettings(true);
        try {
            let response = await updateSurveyPersonalization(
                {
                    surveySettings: surveySettingsFormData,
                    healthConcerns: healthConcernsFormData,
                    lifeImpacts: lifeImpactsFormData,
                    overallHealths: overallHealthsFormData,
                    stresses: stressesFormData,

                    sendResultsToPatients: sendResultsToPatients,
                    sendResultsToClinic: sendResultsToClinic,
                    clinicResultsEmail: clinicResultsEmail,
                    reviewLink: reviewLink,
                },
                user
            );
            if (response?.status === 200) {
                showGlobalSnackbar(i18n.t("successfullySavedSurvey"));
                dispatch(addUser(response?.data?.data));
                setHasMadeAnyChanges(false);
                setFormDataErrors(null);
                console.log('NEW USER: ',response?.data?.data)
            } else {
                setFormDataErrors(response?.response?.data?.messages);
            }
        } catch (error) {
            console.log('error', error);
        }
        setIsUpdatingSettings(false);
    }


    const [isValidating, setIsValidating] = useState(false);
    useEffect(() => {
        const elements = document.querySelectorAll('body, *'); // Select all elements or just the body

        if (isValidating) {
            // Add the loading cursor class globally
            document.body.classList.add('loading-cursor');
            elements.forEach(element => {
                // Optionally, add the class to specific elements as well
                element.classList.add('loading-cursor');
            });
        } else {
            // Remove the loading cursor class when validation is done
            document.body.classList.remove('loading-cursor');
            elements.forEach(element => {
                element.classList.remove('loading-cursor');
            });
        }
    }, [isValidating]);


    async function validateCurrentlyEditingFormData() {

        if(!hasMadeAnyChanges){
            return true;
        }

        setIsValidating(true);
        try {
            let response = await validateSurveyPersonalizationData(
                {
                    surveySettings: surveySettingsFormData[surveySettingsIndex],
                    healthConcerns: healthConcernsFormData[healthConcernsIndex],
                    lifeImpacts: lifeImpactsFormData[lifeImpactsIndex],
                    overallHealths: overallHealthsFormData[overallHealthsIndex],
                    stresses: stressesFormData[stressesIndex],

                    sendResultsToPatients: sendResultsToPatients,
                    sendResultsToClinic: sendResultsToClinic,
                    clinicResultsEmail: clinicResultsEmail,
                    reviewLink: reviewLink,
                },
                user
            );

            if (response?.status === 200) {
                setFormDataErrors(null);
                setIsValidating(false);
                return true;
            } else {
                const messagesWithIndex = mapMessagesToIncludeIndex(response?.response?.data?.messages);
                setFormDataErrors(messagesWithIndex);
                setIsValidating(false);
                return false;
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    function mapMessagesToIncludeIndex(messages) {
        const updatedMessages = {};

        Object.keys(messages).forEach(key => {
            const parts = key.split('.');  // Split the key by dot notation

            // Add the respective index to the key
            let updatedKey = '';

            // Adjust the keys based on the indexes
            if (parts[0] === 'surveySettings') {
                updatedKey = `surveySettings.${surveySettingsIndex}.${parts.slice(1).join('.')}`;
            } else if (parts[0] === 'healthConcerns') {
                updatedKey = `healthConcerns.${healthConcernsIndex}.${parts.slice(1).join('.')}`;
            } else if (parts[0] === 'lifeImpacts') {
                updatedKey = `lifeImpacts.${lifeImpactsIndex}.${parts.slice(1).join('.')}`;
            } else if (parts[0] === 'overallHealths') {
                updatedKey = `overallHealths.${overallHealthsIndex}.${parts.slice(1).join('.')}`;
            } else if (parts[0] === 'stresses') {
                updatedKey = `stresses.${stressesIndex}.${parts.slice(1).join('.')}`;
            } else {
                updatedKey = key; // Default if no index is added
            }

            // Add the updated key with its error messages
            updatedMessages[updatedKey] = messages[key];
        });

        return updatedMessages;
    }

    function getFormDataError(dataGroup, key){
        if(dataGroup === 'surveySettings'){
            return formDataErrors?.[`${dataGroup}.${surveySettingsIndex}.${key}`];
        }else if(dataGroup === 'healthConcerns'){
            return formDataErrors?.[`${dataGroup}.${healthConcernsIndex}.${key}`];
        }else if(dataGroup === 'lifeImpacts'){
            return formDataErrors?.[`${dataGroup}.${lifeImpactsIndex}.${key}`];
        }else if(dataGroup === 'overallHealths'){
            return formDataErrors?.[`${dataGroup}.${overallHealthsIndex}.${key}`];
        }else if(dataGroup === 'stresses'){
            return formDataErrors?.[`${dataGroup}.${stressesIndex}.${key}`];
        }else{
            return formDataErrors?.[key];
        }
    }


    useEffect(() => {
        if (user?.user?.clinic) {
            setSurveySettingsFormData(user?.user?.clinic?.surveySettings);
            setHealthConcernsFormData(user?.user?.clinic?.healthConcerns);
            setLifeImpactsFormData(user?.user?.clinic?.lifeImpacts);
            setOverallHealthsFormData(user?.user?.clinic?.overallHealths);
            setStressesFormData(user?.user?.clinic?.stresses);
            setSendResultsToPatients(user?.user?.clinic?.sendResultsToPatients);
            setSendResultsToClinic(user?.user?.clinic?.sendResultsToClinic);
            setClinicResultsEmail(user?.user?.clinic?.clinicResultsEmail);
            setReviewLink(user?.user?.clinic?.googleLink);
            setIsBasicPlan(user?.user?.clinic?.businessPlan?.id === 1);
        }
    }, [user]); // Dependency array will trigger the effect when 'user' changes

    const [isBasicPlan, setIsBasicPlan] = useState(false);

  return (
      <div style={{
          pointerEvents: isUpdatingSettings ? 'none' : 'auto',
      }}>

          <div style={{
              padding: '0 45px',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '20px',
              marginBottom: '40px',
          }}>
              <button

                  style={{
                      minWidth: '175px',
                      borderRadius: "23px",
                      padding: '0px 20px',
                      fontSize: '14px',
                      lineHeight: '32px',
                      textAlign: 'center',

                      color: surveySettingsIndex !== null && surveySettingsIndex !== undefined ? 'black' : '#A0A0A0',
                      backgroundColor: surveySettingsIndex !== null && surveySettingsIndex !== undefined ? 'white' : '#C5C5C5',
                      border: surveySettingsIndex !== null && surveySettingsIndex !== undefined ? "1px solid rgba(97, 132, 157, 1)" : '#C5C5C5',
                      pointerEvents: surveySettingsIndex !== null && surveySettingsIndex !== undefined ? 'auto' : 'none',
                  }}
                  onClick={() => handleCopyLink()}
              >Copy survey link
              </button>
              <button
                  style={{
                      minWidth: '175px',
                      color: hasMadeAnyChanges ? 'white' : '#A0A0A0',
                      borderRadius: "23px",
                      backgroundColor: hasMadeAnyChanges ? '#61849D' : '#C5C5C5',
                      border: hasMadeAnyChanges ? "1px solid #61849D" : '#C5C5C5',
                      padding: '0px 20px',
                      fontSize: '14px',
                      lineHeight: '32px',
                      display: 'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      pointerEvents: !hasMadeAnyChanges ? 'none' : 'auto',
                  }}
                  onClick={() => handleUpdateSurveySettings()}
              >
                  {
                      isUpdatingSettings ? <div
                          style={{
                              padding: '5px',
                          }}>
                          <Oval
                              height={20}
                              width={20}
                              color="#fff"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#fff"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                          />
                          </div>
                       :
                        'Save changes'
                  }

              </button>

          </div>


          <div style={{
              display: 'grid',
              gridTemplateColumns: 'minmax(0, 400px) 1fr',  // First column max 400px, second takes remaining space
              gap: '30px',
              marginBottom: '50px',
          }}>

              {/* LEFT */}
              <div style={{minHeight: '80vh', display: 'flex', flexDirection: 'column', gap: '30px'}}>
                  {
                      !isBasicPlan && <SurveyLanguageAndTypePicker
                          languages={languages}
                          language={language}
                          setLanguage={setLanguageWithValidate}

                          types={surveyTypes}
                          type={type}
                          setType={setTypeWithValidate}
                      />
                  }


                  <SurveySettingsPersonalizationScreenPicker
                      surveyType={type}
                      activeScreen={activeScreen}
                      setActiveScreen={setActiveScreenWithValidate}
                      currentlyEditingSurveySettings={getCurrentlyEditingSurveySettings()}
                  />

                  <SurveyAdditionalSettings

                      hasMadeAnyChanges={hasMadeAnyChanges}
                      setHasMadeAnyChanges={setHasMadeAnyChanges}
                      sendResultsToPatients={sendResultsToPatients}
                      setSendResultsToPatients={setSendResultsToPatients}
                      sendResultsToClinic={sendResultsToClinic}
                      setSendResultsToClinic={setSendResultsToClinic}
                      clinicResultsEmail={clinicResultsEmail}
                      setClinicResultsEmail={setClinicResultsEmail}
                      getError={getFormDataError}
                  />
              </div>
              {/* END LEFT */}


              {/* RIGHT */}
              <div style={{minHeight: '80vh', padding: '0 45px'}}>
                  {
                      (isBasicPlan && activeScreen) && <div style={{marginBottom: '20px'}}>
                          <PersonalizationInfoCard
                              text={i18n.t("unlockOptionText")}
                              backgroundColor={'#61849D26'}
                              button={<button
                                  style={{
                                      marginLeft: '15px',
                                      alignSelf:'center',
                                      minWidth: '150px',
                                      backgroundColor: 'white',
                                      borderRadius: "23px",
                                      border: "1px solid rgba(97, 132, 157, 1)",
                                      padding: '0px 20px',
                                      fontSize: '14px',
                                      lineHeight: '32px',
                                      textAlign: 'center',
                                      whiteSpace: 'nowrap',
                                  }}
                                  onClick={() => navigate("/dashboard/price-plans")}
                              >
                                  {i18n.t("unlockOptionButton")}
                              </button>}
                          />
                      </div>
                  }

                  <SurveySettingsPersonalizationScreens
                      isBlocked={isBasicPlan}
                      isUpdatingSettings={isUpdatingSettings}
                      activeScreen={activeScreen}

                      languages={languages}
                      activeLanguage={language}
                      setActiveLanguage={setLanguageWithValidate}

                      allSurveySettingsFormData={surveySettingsFormData}
                      setAllSurveySettingsFormData={setAllSurveySettingsFormData}

                      surveySettingsFormData={getCurrentlyEditingSurveySettings()}
                      setSurveySettingsFormData={setStateForCurrentlyEditingSurveySettings}

                      healthConcernsFormData={getCurrentlyEditingHealthConcerns()}
                      setHealthConcernsFormData={setStateForCurrentlyEditingHealthConcerns}

                      lifeImpactsFormData={getCurrentlyEditingLifeImpacts()}
                      setLifeImpactsFormData={setStateForCurrentlyEditingLifeImpacts}

                      overallHealths={getCurrentlyEditingOverallHealths()}
                      setOverallHealths={setStateForCurrentlyEditingOverallHealths}

                      stressesFormData={getCurrentlyEditingStresses()}
                      setStressesFormData={setStateForCurrentlyEditingStresses}

                      defaultBodyMetrics={defaultBodyMetrics}

                      showGlobalSnackbar={showGlobalSnackbar}

                      formDataErrors={formDataErrors}

                      getError={getFormDataError}

                      reviewLink={reviewLink}
                      setReviewLink={handleSetReviewLink}
                  />
              </div>
              {/* END RIGHT */}


          </div>
      </div>
  );
};

export default SurveySettings;
