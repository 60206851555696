import React, {useEffect, useState} from 'react';
import './EditNextThankYouSurvey.scss';
import {useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {deletePreviousSurveyData, deleteSurveyData, updateSurveyPage} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";


const EditNextThankYouSurvey = ({showGlobalSnackbar, survey, i18n, app}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const dispatch = useDispatch();


    const [patientName, setPatientName] = useState(null);

    useEffect(() => {
        if (survey?.previousSurveyData?.patient?.name || survey?.patient?.name) {
            setPatientName(survey?.previousSurveyData?.patient?.name ?? survey?.patient?.name);
        }
    }, [survey]);

    useEffect(() => {
        if (patientName) {
            dispatch(deleteSurveyData());
        }
    }, [patientName]);


    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateSurveyPage(5));
        // dispatch(deleteSurveyData());
        dispatch(deletePreviousSurveyData());
    }, []);


    const nextPage = () => {
      //
    }


    return (
        <div className="FirstScreenSurvey">

            <SurveyHero
                step={survey?.settings?.thankSubtitle
                    ? survey.settings.thankSubtitle.trim().endsWith('!')
                        ? `${survey.settings.thankSubtitle.slice(0, -1)} ${patientName ?? ''}!`
                        : `${survey.settings.thankSubtitle} ${patientName ?? ''}`
                    : ''}
                headline={survey?.settings?.thankHeadline}
                // step={survey?.settings?.thankSubtitle}
                // headline={survey?.settings?.thankHeadline ? `${survey?.settings?.thankHeadline} ${patientName ?? ""}` : ''}
                text={survey?.settings?.thankText}
                videoLink={survey?.settings?.thankVideo}
                buttonText={""}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
            />


        </div>
    );
};

export default EditNextThankYouSurvey;
