import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAppData,
  resetPopUp,
  setAppTheme,
  showSnackbar,
} from "../../../../redux/actions/app";
import { Box } from "@mui/material";
import "./Main.scss";
import "../../../../elements/components/Survey/HealthMetricsQuestions/healthMetricsQuestions.scss";


import SideNavigation from "../../../components/Admin Panel/SideNavigation/SideNavigation";
import Search from "../../../components/Admin Panel/Search/Search";
import { addUser, logoutUser } from "../../../../redux/actions/user";
import { useTranslation } from "react-i18next";
import ConfirmPopUp from "../../../components/Admin Panel/ConfirmPopUp/ConfirmPopUp";
import {
  appData,
  getMyUser,
  refreshTokenApi,
  chooseLanguage,
} from "../../../../core/axios/services";
import hamburger from "../../../../../src/Ic_menu.svg";
import close from "../../../../../src/Ic_close.svg";
import { paymentCheck } from "../../../../core/axios/services";
import { showPopup } from "../../../../redux/actions/app";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import Update from "../../../components/Update/Update";

const Main = ({ showGlobalSnackbar, paid }) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [checkSubscription, setCheckSubscription] = useState("");

  const collectAppData = async () => {
    return appData().then((res) => res.data.data);
  };

  const [showBusinessPlan, setShowBusinessPlan] = useState("");

  useEffect(() => {
    if (
      window.location.host == "stage.chiroforms.com" ||
      window.location.host == "chiroforms.com"
    ) {
      navigate("/not-found");
    }
  }, []);

  const getMeData = async () => {
    return getMyUser(user)
      .then((res) => res.data.data)
      .catch(() => {
        if (user.rememberMe) {
          refreshToken();
        }

        dispatch(logoutUser());
        navigate("/login");
        showGlobalSnackbar(i18n.t("thereWasAnError"));
      });
  };

  const getMyInfo = async () => {
    return getMyUser(user)
      .then((res) => {
        setShowBusinessPlan(res.data.data.clinic.businessPlan.id);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getMyInfo();
  }, [user]);

  const refreshToken = async () => {
    try {
      return refreshTokenApi(user).then((res) => res.data.data);
    } catch {
      dispatch(logoutUser());
      navigate("/login");
      showGlobalSnackbar(i18n.t("thereWasAnError"));
    }
  };

  useEffect(() => {
    //do not translate admin panel
    i18n.changeLanguage("en");
    // resetPopup & snackbar, in case it was left open
    dispatch(resetPopUp());
    dispatch(
      showSnackbar({
        showSnackbar: false,
        snackbarMessage: "",
      })
    );
    dispatch(setAppTheme("defaultTheme"));
    collectAppData().then((data) => dispatch(getAppData(data)));
    if (user.token === "") {
      navigate("/login");
    }

    getMeData().then((data) => {
      dispatch(addUser(data));
    });
  }, []);

  const handleMenuOpen = () => {
    setOpen(!open);
  };

  // Payment
  const checkPaymentMain = async () => {
    try {
      return paymentCheck(user).then((res) => {
        // console.log(user.user);
        // if (user.user === null) {
        //   window.location.reload(true);
        // }
        // window.location.reload(true);
        setCheckSubscription(res.data.data.subscribed);
        if (res.data.data.subscribed !== true && user.user.clinic != null) {
          const data = {
            showPopup: true,
            data: {
              title: i18n.t("paymentPopupTitle", { name: user.user.firstName }),
              subtitle: i18n.t("paymentPopupSubtitle"),
              confirmButton: i18n.t("paymentPopupButton"),
              denyButton: i18n.t("logoutButton"),
              type: "restartPatment",
            },
          };
          dispatch(showPopup(data));
        }
      });
    } catch {
      alert("error");
    }
  };

  const openLogoutPopup = () => {
    setTimeout(() => {
      const data = {
        showPopup: true,
        data: {
          title: i18n.t("areYouSureYouWantToLogout"),
          confirmButton: i18n.t("confirmLogout"),
          denyButton: i18n.t("denyLogout"),
          type: "logout",
        },
      };
      dispatch(showPopup(data));
    }, 500);
  };

  const checkUserStatus = () => {
    if (user.user) {
      if (user.user.verified === false) {
        const data = {
          showPopup: true,
          data: {
            title: i18n.t("completeYourProfile", {
              name: user.user.firstName,
            }),
            confirmButton: i18n.t("continue"),
            denyButton: i18n.t("logoutButton"),
            type: "completeOtp",
          },
        };

        dispatch(showPopup(data));
      } else if (user.user.clinic === null) {
        const data = {
          showPopup: true,
          data: {
            title: i18n.t("completeYourProfile", {
              name: user.user.firstName,
            }),
            confirmButton: i18n.t("continue"),
            denyButton: i18n.t("logoutButton"),
            type: "completeProfile",
          },
        };

        dispatch(showPopup(data));
      } else {
        checkPaymentMain();
      }
    }
    if (
      paid === true &&
      // checkSubscription === true &&
      location.pathname !== "/pricing"
    ) {
      if (showBusinessPlan == 1) {
        const data = {
          showPopup: true,
          data: {
            title: "Hello, welcome to Chiropractic Outcomes!",
            topSecondTitle:
              "Please select the language you will use for the Survey",
            bottomDescription:
              "To get started, please watch the quick and easy tutorial videos in the Help Center tab. You will learn how to use the platform in no time!",
            confirmButton: "Get Started",
            type: "welcomePopup",
          },
        };
        dispatch(showPopup(data));
      }

      if (showBusinessPlan != 1) {
        const dataCenter = {
          showPopup: true,
          data: {
            description: i18n.t("successPayment"),
            title: "Hello, welcome to Chiropractic Outcomes!",
            confirmButton: i18n.t("close"),
            type: "successPayment",
          },
        };
        dispatch(showPopup(dataCenter));
      }
    }
    //  user.clinic proveriti
  };

  useEffect(() => {
    checkUserStatus();
  }, [user.user]);

  const sendLanguage = () => {
    chooseLanguage(localStorage.getItem("language") ?? 1, user).then((res) => {
      getMeData().then((data) => {
        dispatch(addUser(data));
        setTimeout(() => {
          window.location.reload();
        }, 350);
      });
      navigate("/dashboard");
    });
    localStorage.removeItem("language");
  };

  useEffect(() => {
    if (location.pathname === "/dashboard-paid") {
      // This code will run only once when the component first loads
      // Add your logic here for what you want to do on the initial render
      console.log("Initial render on /dashboard");
    }
  }, []);

  useEffect(() => {
    const data = {
      showPopup: false,
      data: {
        title: "",
        confirmButton: "",
        type: "",
      },
    };
    dispatch(showPopup(data));
  }, []);

  useEffect(() => {
    if (app.popupStatus === true) {
      switch (app.popUpData?.type) {
        case "completeOtp":
          dispatch(resetPopUp());
          navigate("/otp/again");
          break;
        case "completeProfile":
          dispatch(resetPopUp());
          navigate("/details-popup");
          break;
        case "restartPatment":
          navigate("/pricing");
          dispatch(resetPopUp());
          break;

        case "successPayment":
          dispatch(resetPopUp());
          break;

        case "welcomePopup":
          dispatch(resetPopUp());
          sendLanguage();
          break;
        // case "welcomePopup":
        //   sendLanguage();
        //   break;
      }
    } else if (app.popupStatus === false) {
      switch (app.popUpData?.type) {
        case "restartPatment":
        case "completeOtp":
        case "completeProfile":
          openLogoutPopup();
          break;
        case "logout":
          checkPaymentMain();
          break;
      }
      dispatch(resetPopUp());
    }
  }, [app]);

  return (
    <div className={"main"}>
      <Update />
      {user.token !== "" ? (
        <Box className={"mainBox"}>
          <div className={"containerForSideNavigation"}>
            <SideNavigation app={app} user={user} />
          </div>

          {open ? (
            <div>
              {" "}
              <div className={"mobileMenu"}>
                <span onClick={handleMenuOpen}>
                  <img src={close} alt="close" />
                </span>
                <SideNavigation
                  app={app}
                  user={user}
                  onClick={handleMenuOpen}
                />
              </div>
              <div className="blur" onClick={handleMenuOpen}></div>
            </div>
          ) : (
            ""
          )}

          <Box className={"innerBox"}>
            <Box className={"routerBox"}>
              <div className={"searchMenuWrapper"}>
                {location.pathname == "/dashboard" ? (
                  <Search showGlobalSnackbar={showGlobalSnackbar} user={user} />
                ) : (
                  <div style={{ height: "62px" }} />
                )}
                <div className={"containerForMobileSideNavigation"}>
                  <div onClick={handleMenuOpen}>
                    <img
                      src={hamburger}
                      alt="hamburger"
                      width="20px"
                      height="14px"
                    />
                  </div>
                </div>
              </div>
              <Outlet />
            </Box>
          </Box>
        </Box>
      ) : null}
      <ConfirmPopUp app={app} />
    </div>
  );
};

export default Main;
