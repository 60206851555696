export default function PersonalizationInfoCard({text, backgroundColor = "rgba(222, 214, 199, 1)", boxShadow = 'none', button}) {
    return <div
        style={{
            padding:'20px',
            borderRadius: "10px",
            backgroundColor: backgroundColor,
            boxShadow: boxShadow,
            alignItems: "flex-start",
            display:'flex',
            gap:"10px",
        }}
    >
        <img src={'/assets/info_icon_gray.svg'} alt={'information'}/>
        <p
            style={{
                textAlign:'left',
                margin:0,
                padding:0,
                color: "rgba(73, 73, 73, 1)",
                fontFamily: "HelveticaNeueRegular",
                letterSpacing: "0px",
                lineHeight: "21px"
            }}
        >{text}</p>
        {button}
    </div>
}