import React, {useEffect, useState} from 'react';
import './NextIntroSurvey.scss';
import {useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {
    deletePreviousSurveyData,
    deleteSurveyData, updateSurveyData,
    updateSurveyPage, updateSurveyPatient,
    updateSurveyPatientId
} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";
import {getSurveyById} from "../../../../../core/axios/services";


const NextIntroSurvey = ({showGlobalSnackbar, survey, i18n, app, isNext}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const {previousId} = useParams();

    const dispatch = useDispatch();


    const hasFollowingSurveyCompleted = survey?.previousSurveyData?.hasFollowingSurveyCompleted;

    useEffect(() => {
        dispatch(updateSurveyPatientId(null));
        dispatch(updateSurveyPage(0));
        window.scrollTo(0, 0)



        getSurveyById(previousId).then((res) => {
            dispatch(updateSurveyPatient(res.data.data.patient))
        })
    }, []);


    const nextPage = () => {
        dispatch(deleteSurveyData());
        dispatch(updateSurveyPage(1));
        if (isNext) {
            navigate("/survey-next/" + code + "/" + previousId + "/health-concerns");

        } else {
            navigate("/survey/" + code + "/patient-profile");
        }
    }


    return (
        <div className="FirstScreenSurvey">

            {
                hasFollowingSurveyCompleted &&
                <div className="ScreenOverlay">
                    <div className="ScreenContent">
                        <h1 style={{textAlign:'center'}}>{i18n.t('alreadyCompletedSurvey')}</h1>
                    </div>
                </div>
            }

            <SurveyHero
                headline={survey?.settings?.nextIntroHeadline}
                step={
                    survey?.settings?.nextIntroSubtitle
                        ? survey.settings.nextIntroSubtitle.trim().endsWith('!')
                            ? `${survey.settings.nextIntroSubtitle.slice(0, -1)} ${survey?.patient?.name ?? ''}!`
                            : `${survey.settings.nextIntroSubtitle} ${survey?.patient?.name ?? ''}`
                        : ''
                }
                // step={survey?.settings?.nextIntroSubtitle}
                // headline={
                //     survey?.settings?.nextIntroHeadline
                //         ? survey.settings.nextIntroHeadline.trim().endsWith('!')
                //             ? `${survey.settings.nextIntroHeadline.slice(0, -1)} ${survey?.patient?.name ?? ''}!`
                //             : `${survey.settings.nextIntroHeadline} ${survey?.patient?.name ?? ''}`
                //         : ''
                // }
                text={survey?.settings?.nextIntroText}
                videoLink={survey?.settings?.nextIntroVideo}
                buttonText={i18n.t("startSurvey")}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
            />


        </div>
    );
};

export default NextIntroSurvey;
