import React, {useEffect, useState} from 'react';
import './IntroSurvey.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import {
    deletePreviousSurveyData,
    deleteSurveyData,
    updateSurveyPage, updateSurveyPatient,
    updateSurveyPatientId
} from "../../../../../redux/actions/survey";
import {useDispatch} from "react-redux";
import {getPatientNameById} from "../../../../../core/axios/services";


const IntroSurvey = ({showGlobalSnackbar, survey, i18n, app, isNext, themeColor}) => {

    const navigate = useNavigate();
    const {code} = useParams();
    const {previousId} = useParams();

    let patientId = (new URLSearchParams(useLocation().search)).get('patientId');

    const dispatch = useDispatch();


    const [patientName, setPatientName] = useState("");

    async function handleGetPatientNameData() {
        try {
            const response = await getPatientNameById(patientId);
            return response;
        } catch (error) {
            console.error("Error fetching patient name:", error);
            return null;
        }
    }

    useEffect(() => {
        if (patientId) {
            async function fetchPatientData() {
                const patientData = await handleGetPatientNameData();
                setPatientName(patientData?.data?.data?.name || "");
            }

            fetchPatientData();
        }
    }, [patientId]);



    useEffect(() => {
        dispatch(updateSurveyPatientId(null))
        dispatch(updateSurveyPage(0));
        dispatch(updateSurveyPage(0));
        dispatch(deleteSurveyData());
        dispatch(deletePreviousSurveyData());
        window.scrollTo(0, 0)
    }, []);

    const nextPage = () => {
        dispatch(deleteSurveyData());
        dispatch(deletePreviousSurveyData());
        dispatch(updateSurveyPage(1));
        if (isNext) {
            navigate("/survey-next/" + code + "/" + previousId + "/health-concerns");

        } else {
            if(patientId){
                dispatch(updateSurveyPatientId(patientId))
                if (survey.settings.type === 1 || survey.settings.type === 3) {
                    //basic
                    dispatch(updateSurveyPage(3));
                    navigate("/survey/" + code + "/health-concerns");
                } else if (survey.settings.type === 2) {
                    //advanced
                    dispatch(updateSurveyPage(2));
                    navigate("/survey/" + code + "/health-stresses");
                }
            }else{
                navigate("/survey/" + code + "/patient-profile");
            }
        }
    }


    return (
        <div className="FirstScreenSurvey">

            <SurveyHero
                i18n={i18n}
                // step={survey?.settings?.introSubtitle}
                step={survey?.settings?.introSubtitle
                    ? survey.settings.introSubtitle.trim().endsWith('!')
                        ? `${survey.settings.introSubtitle.slice(0, -1)} ${patientName ?? ''}!`
                        : `${survey.settings.introSubtitle} ${patientName ?? ''}`
                    : ''}
                headline={survey?.settings?.introHeadline}
                text={survey?.settings?.introText}
                videoLink={survey?.settings?.introVideo}
                buttonText={i18n.t("startSurvey")}
                clickedButton={() => nextPage()}
                app={app}
                surveyType={survey?.settings?.type}
                showExistingPatientData={patientId}
            />


        </div>
    );
};

export default IntroSurvey;
