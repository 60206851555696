import React, { useEffect, useState } from "react";
import "./EditableNextHealthConcernsSurvey.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSurveyData,
  updateSurveyPage,
} from "../../../../../redux/actions/survey";
import SurveyHero from "../../../../components/Survey/SurveyHero/SurveyHero";
import { Box } from "@mui/material";
import HealthConcernText from "../../../../components/Survey/HealthConcernText/HealthConcernText";
import getThemes from "../../../../../constants/colors/getTheme";
import Button from "../../../../components/Comon/Button/Button";
import LinkText from "../../../../components/Comon/LinkText/LinkText";

const EditableNextHealthConcernsSurvey = ({
  showGlobalSnackbar,
  survey,
  i18n,
  app,
}) => {
  const [concerns, setConcerns] = useState([]);
  const [themeColor, setThemeColor] = useState([]);

  const navigate = useNavigate();
  const { code } = useParams();
  const { previousId } = useParams();
  const { surveyId } = useParams();
  const dispatch = useDispatch();

  const getHealtConcernsForCurrentLanguage = () => {
    return survey?.settings?.clinic?.healthConcerns.find(
      (item) => item.language.id === survey?.settings?.language?.id
    );
  };

  const getLifeImpactForCurrentLanguage = () => {
    return survey?.settings?.clinic?.lifeImpacts.find(
      (item) => item.language.id === survey?.settings?.language?.id
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(updateSurveyPage(1));
  }, []);

  let initialConcerns = [
    {
      name: "firstHealthConcern",
      topText: getHealtConcernsForCurrentLanguage()?.question1Subtitle,
      headline:
        i18n.t("ratePreviousConcern")+getHealtConcernsForCurrentLanguage()?.question1Title?.toLowerCase()+": " +
        survey.previousSurveyData.healthConcernsFirstConcern,
      text: i18n.t("youRatedItLastTime", {
        rate: survey.previousSurveyData.healthConcernsFirstRate,
      }),
      leftOption: getHealtConcernsForCurrentLanguage()?.question1LowScale,
      rightOption: getHealtConcernsForCurrentLanguage()?.question1HighScale,
      concern: survey.previousSurveyData.healthConcernsFirstConcern,
      concernRate:
        survey.data &&
        survey.data.healthConcerns &&
        survey.data?.healthConcerns[0]?.concernRate
          ? survey.data.healthConcerns[0].concernRate
          : 0,
    },
    {
      name: "secondHealthConcern",
      topText: "",
      headline:
        i18n.t("ratePreviousConcern")+getHealtConcernsForCurrentLanguage()?.question2Title?.toLowerCase()+": " +
        survey.previousSurveyData.healthConcernsSecondConcern,
      text: i18n.t("youRatedItLastTime", {
        rate: survey.previousSurveyData.healthConcernsSecondRate,
      }),
      leftOption: getHealtConcernsForCurrentLanguage()?.question2LowScale,
      rightOption: getHealtConcernsForCurrentLanguage()?.question2HighScale,
      concern: survey.previousSurveyData.healthConcernsSecondConcern,
      concernRate:
        survey.data &&
        survey.data.healthConcerns &&
        survey.data?.healthConcerns[1]?.concernRate
          ? survey.data.healthConcerns[1].concernRate
          : 0,
    },
    {
      name: "thirdHealthConcern",
      topText: "",
      headline:
        i18n.t("ratePreviousConcern")+getHealtConcernsForCurrentLanguage()?.question3Title?.toLowerCase()+": " +
        survey.previousSurveyData.healthConcernsThirdConcern,
      text: i18n.t("youRatedItLastTime", {
        rate: survey.previousSurveyData.healthConcernsThirdRate,
      }),
      leftOption: getHealtConcernsForCurrentLanguage()?.question3LowScale,
      rightOption: getHealtConcernsForCurrentLanguage()?.question3HighScale,
      concern: survey.previousSurveyData.healthConcernsThirdConcern,
      concernRate:
        survey.data &&
        survey.data.healthConcerns &&
        survey.data?.healthConcerns[2]?.concernRate
          ? survey.data.healthConcerns[2].concernRate
          : 0,
    },
    {
      name: "fourthHealthConcern",
      topText: getLifeImpactForCurrentLanguage()?.question1Subtitle,
      headline:
        getLifeImpactForCurrentLanguage()?.question1Title +
        " " +
        survey.previousSurveyData.healthConcernsFourthConcern,
      text: i18n.t("youRatedItLastTime", {
        rate: survey.previousSurveyData.healthConcernsFourthRate,
      }),
      leftOption: getLifeImpactForCurrentLanguage()?.question1LowScale,
      rightOption: getLifeImpactForCurrentLanguage()?.question1HighScale,
      concern: survey.previousSurveyData.healthConcernsFourthConcern,
      concernRate:
        survey.data &&
        survey.data.healthConcerns &&
        survey.data?.healthConcerns[3]?.concernRate
          ? survey.data.healthConcerns[3].concernRate
          : 0,
    },
  ];

  useEffect(() => {
    if (survey.previousSurveyData.healthConcernsThirdRate === 0) {
      const index = initialConcerns.findIndex(
        (c) => c.name === "thirdHealthConcern"
      );
      if (index !== -1) {
        initialConcerns[index].visible = false;
      }
    }

    if (survey.previousSurveyData.healthConcernsSecondRate === 0) {
      const index = initialConcerns.findIndex(
        (c) => c.name === "secondHealthConcern"
      );
      if (index !== -1) {
        initialConcerns[index].visible = false;
      }
    }

    if (survey.previousSurveyData.healthConcernsFourthRate === 0) {
      const index = initialConcerns.findIndex(
        (c) => c.name === "fourthHealthConcern"
      );
      if (index !== -1) {
        initialConcerns[index].visible = false;
      }
    }

    setConcerns(initialConcerns);
  }, [survey]);

  useEffect(() => {
    setThemeColor(getThemes("mainColor", app.appTheme));
  }, [app]);

  const nextPage = () => {
    dispatch(updateSurveyPage(2));
    navigate(
      "/survey-next-edit/" +
        code +
        "/" +
        surveyId +
        "/" +
        previousId +
        "/global-improvements"
    );
  };

  const previousPage = () => {
    navigate("/survey-next-edit/" + code + "/" + surveyId + "/" + previousId);
  };

  const updateConcern = (index, value, type) => {
    if (type === "text") {
      concerns[index].concern = value;
    } else {
      concerns[index].concernRate = value;
    }

    setConcerns([...concerns]);

    if (survey.data == null) {
      survey.data = { healthConcerns: concerns };
    } else {
      survey.data.healthConcerns = concerns;
    }
    dispatch(updateSurveyData(survey.data));
  };

  const scrollTo = () => {
    const element = document.getElementById(concerns[0].name);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <div className="ThirdScreenSurvey">
      <SurveyHero
        // step={i18n.t("step") + " 1"}
        headline={getHealtConcernsForCurrentLanguage()?.title}
        text={getHealtConcernsForCurrentLanguage()?.desc}
        videoLink={getHealtConcernsForCurrentLanguage()?.videoUrl}
        buttonText={i18n.t("startSurvey")}
        clickedButton={() => scrollTo()}
        app={app}
        surveyType={survey?.settings?.type}
      />

      <div className={"concernsContainer"}>
        {concerns.map((concern, index) => {
          return (
            <div className={"concernContainer"} key={index}>
              {concern.visible !== false ? (
                <HealthConcernText
                  setConcernText={(value) =>
                    updateConcern(index, value, "text")
                  }
                  setConcernRate={(value) =>
                    updateConcern(index, value, "rate")
                  }
                  mandatory={concern.mandatory}
                  text={concern.text}
                  concernRate={concern.concernRate}
                  topText={concern.topText}
                  headline={concern.headline}
                  themeColor={themeColor}
                  hasTextInput={false}
                  leftOption={concern.leftOption}
                  rightOption={concern.rightOption}
                  i18n={i18n}
                  id={concern.name}
                  concernText={concern.concern}
                />
              ) : null}
            </div>
          );
        })}
      </div>
      <div className={"buttonsContainer"}>
        <div className={"nextContainer"} onClick={() => nextPage()}>
          <Button color={themeColor} mode={"small"} text={i18n.t("nextStep")} />
        </div>
        <div className={"previousContainer"} onClick={() => previousPage()}>
          <LinkText text={i18n.t("previousStep")} />
        </div>
      </div>
    </div>
  );
};

export default EditableNextHealthConcernsSurvey;
