import axios from "axios";
import { API } from "../../constants/services/base";

// general
export const axiosConfig = (user) => {
  return {
    headers: {
      Authorization: `Bearer ${user.token}`,
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  };
};

export const axiosConfigDownload = (user) => {
  return {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: "application/pdf",
    },
    responseType: "blob",
  };
};

export const authLogin = async (email, password, type) => {
  try {
    return await axios.post(`${API}/auth`, {
      email,
      password,
      type,
    });
  } catch (error) {
    throw error;
  }
};

export const authSignUp = async (email, password, passwordRepeat) => {
  try {
    return await axios.post(`${API}/me`, {
      email,
      password,
      passwordRepeat,
    });
  } catch (error) {
    throw error;
  }
};

export const authOtp = async (email, code) => {
  try {
    return await axios.post(`${API}/me/verify`, {
      email,
      code,
    });
  } catch (error) {
    throw error;
  }
};

export const authResendVerify = async (email) => {
  try {
    return await axios.post(`${API}/me/resend-verification`, {
      email,
    });
  } catch (error) {
    throw error;
  }
};

export const authDetails = async (
  id,
  email,
  password,
  clinicName,
  firstName,
  lastName,
  address,
  dataProtection
) => {
  try {
    return await axios.put(`${API}/me-complete`, {
      id,
      email,
      password,
      clinicName,
      firstName,
      lastName,
      address,
      dataProtection
    });
  } catch (error) {
    throw error;
  }
};

export const authDetailsAuth = async (
  id,
  email,
  password,
  clinicName,
  firstName,
  lastName,
  user,
  address,
  dataProtection
) => {
  try {
    return await axios.put(
      `${API}/me-complete-auth`,
      {
        id,
        email,
        password,
        clinicName,
        firstName,
        lastName,
        user,
        address,
        dataProtection
      },
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const payment = async (type, user) => {
  try {
    return await axios.get(
      `${API}/start-subscription/` + type,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const authForgotPassword = async (email) => {
  try {
    return await axios.get(`${API}/password-reset/` + email, {
      email,
    });
  } catch (error) {
    throw error;
  }
};

export const authResetPassword = async (token, password) => {
  try {
    return await axios.post(`${API}/password-reset`, {
      token,
      password,
    });
  } catch (error) {
    throw error;
  }
};

export const paymentCheck = async (user) => {
  try {
    return await axios.get(`${API}/subscription/check`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const refreshTokenApi = async (user) => {
  try {
    return await axios.get(`${API}/auth/refresh`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const bodyMetricsDefauls = async (user) => {
  try {
    return await axios.get(`${API}/body-metrics/default`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const appData = async () => {
  try {
    return await axios.get(`${API}/app-data`, axiosConfig({ token: "" }));
  } catch (error) {
    throw error;
  }
};

export const getVersion = async () => {
  try {
    return await axios.get(`${API}/version`, axiosConfig({ token: "" }));
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (email, password, newPassword, user) => {
  try {
    return await axios.post(
      `${API}/auth/reset/password`,
      { email, password, newPassword },
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

// user
export const putUser = async (user, authUser) => {
  try {
    return await axios.put(`${API}/user`, user, axiosConfig(authUser));
  } catch (error) {
    throw error;
  }
};

export const updateDataProtection = async (data, authUser) => {
  try {
    return await axios.put(`${API}/user/data/protection`, data, axiosConfig(authUser));
  } catch (error) {
    throw error;
  }
};

export const updateReviewLink = async (link, user) => {
  try {
    return await axios.post(
      `${API}/clinic/link`,
      { link: link },
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const getMyUser = async (user) => {
  try {
    return await axios.get(`${API}/user`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

// videos

export const getMyVideos = async (user) => {
  try {
    return await axios.get(`${API}/help/videos`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getMyVideoCenter = async (user) => {
  try {
    return await axios.get(`${API}/help/centers`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};
// end videos

// patient

export const getPatients = async (
  clinicId,
  page,
  showAll,
  sortDirection,
  sort,
  activeFilter,
  user
) => {
  const data = {
    clinicId,
    page,
    showAll,
    sort,
    sortDirection,
    active:activeFilter
  };
  try {
    return await axios.post(`${API}/patients`, data, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getPractitioners = async (
  clinicId,
  page,
  showAll,
  sortDirection,
  sort,
  user
) => {
  const data = {
    clinicId,
    page,
    showAll,
    sort,
    sortDirection,
  };
  try {
    return await axios.post(`${API}/practitioners`, data, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getAllPractitioners = async (user) => {
  try {
    return await axios.get(`${API}/practitioners/all`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const assignPractitioner = async (user, data) => {
  try {
    return await axios.post(
      `${API}/practitioner/assign`,
      data,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const getPractitionerStatistics = async (user, id) => {
  try {
    return await axios.get(
      `${API}/statistics/practitioner/${id}`,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const bulkAssignPractitioner = async (user, data) => {
  try {
    return await axios.post(
      `${API}/practitioner/bulk/assign`,
      data,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const checkPractitionerPayment = async (user) => {
  try {
    return await axios.get(`${API}/check-practitioners`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const newPractitioner = async (user, practitioner) => {
  try {
    return await axios.post(
      `${API}/practitioner`,
      practitioner,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const getPractitioner = async (user, id) => {
  try {
    return await axios.get(`${API}/practitioner/${id}`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const updatePractitioner = async (user, practitioner) => {
  try {
    return await axios.put(
      `${API}/practitioner`,
      practitioner,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const deletePractitioner = async (user, id) => {
  try {
    return await axios.delete(`${API}/practitioner/${id}`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};


export const updatePatientsSort = async (sort, sortDirection, user) => {
  const data = {
    sort,
    sortDirection,
  };
  try {
    return await axios.patch(
      `${API}/patient/sort/${sort}/${sortDirection}`,
      data,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const chooseLanguage = async (languageId, user) => {
  const data = {
    languageId,
  };
  try {
    return await axios.patch(
      `${API}/clinic/language/${languageId}`,
      data,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};


export const updatePatientApi = async (patient, user) => {
  try {
    return await axios.put(`${API}/patient`, patient, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const deletePatient = async (patientId, user) => {
  try {
    return await axios.delete(`${API}/patient/` + patientId, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const deleteSurvey = async (surveyId, user) => {
  try {
    return await axios.delete(`${API}/survey/` + surveyId, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getPatient = async (patientId, user) => {
  try {
    return await axios.get(`${API}/patient/` + patientId, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const sendLinkToEmail = async (link, patientId, user) => {
  try {
    return await axios.post(
      `${API}/send/survey`,
      { link, patientId },
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const getPatientSurveys = async (patientId, user) => {
  try {
    return await axios.get(
      `${API}/survey/patient/` + patientId,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

// user

export const getUsers = async (page, showAll, sortDirection, user) => {
  const data = {
    page,
    showAll,
    sortDirection,
  };
  try {
    return await axios.post(`${API}/users`, data, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const updateUserApi = async (selectedUser, user) => {
  try {
    return await axios.put(`${API}/user`, selectedUser, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const createUserApi = async (selectedUser, user) => {
  try {
    return await axios.post(`${API}/user`, selectedUser, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (userId, user) => {
  try {
    return await axios.delete(`${API}/user/` + userId, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getUser = async (userId, user) => {
  try {
    return await axios.get(`${API}/user/` + userId, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

// survey settings

export const updateSurveySettings = async (settings, user) => {
  try {
    return await axios.put(
      `${API}/settings/survey`,
      settings,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const updateBodyMetricsUpdate = async (settings, user) => {
  try {
    return await axios.put(`${API}/body-metric`, settings, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const updateSurveyClinicSettings = async (
  settings,
  user,
  surveySettingsId
) => {
  try {
    return await axios.put(
      `${API}/settings/mail/clinic/toggle/${surveySettingsId}`,
      settings,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

// export const sendEmailsSurvey = async (settingsId, data, user) => {
//   try {
//     return await axios.patch(
//       `${API}/settings/mail/toggle/${settingsId}`,
//       data,
//       axiosConfig(user)
//     );
//   } catch (error) {
//     throw error;
//   }
// };

// search

export const doSearch = async (searchText, user) => {
  try {
    return await axios.get(`${API}/search/` + searchText, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

// statistics

export const getStatistics = async (data, user) => {
  try {
    return await axios.post(`${API}/statistics`, data, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

// survey

export const downloadPdf = async (id, user) => {
  try {
    return await axios.get(
      `${API}/survey/pdf/` + id,
      axiosConfigDownload(user)
    );
  } catch (error) {
    throw error;
  }
};

export const updateSurveyNotes = async (survey, user) => {
  try {
    return await axios.post(`${API}/survey/notes`, survey, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getSettingsFromCode = async (code) => {
  try {
    return await axios.get(`${API}/settings/` + code);
  } catch (error) {
    throw error;
  }
};

export const getPreviousSurvey = async (id) => {
  try {
    return await axios.get(`${API}/survey/` + id);
  } catch (error) {
    throw error;
  }
};

// Popup
export const getPopupStatus = async (user) => {
  try {
    return await axios.get(`${API}/popup/get`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

// export const getPopupCloseStatus = async (id, user) => {
//   try {
//     return await axios.patch(`${API}/popup/close/${id}`, axiosConfig(user));
//   } catch (error) {
//     throw error;
//   }
// };

export const getPopupCloseStatus = async (id, user) => {
  try {
    return await axios.patch(`${API}/popup/close/${id}`, id, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getSurveyById = async (id) => {
  try {
    return await axios.get(`${API}/survey/` + id);
  } catch (error) {
    throw error;
  }
};

export const isSurveyEditable = async (id, user) => {
  try {
    return await axios.get(`${API}/editable/survey/` + id, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const checkIfPatientExists = async (email) => {
  try {
    return await axios.get(`${API}/email/patient/` + email);
  } catch (error) {
    throw error;
  }
};

export const createSurvey = async (data) => {
  try {
    return await axios.post(`${API}/survey`, data);
  } catch (error) {
    throw error;
  }
};

export const updateSurvey = async (data) => {
  try {
    return await axios.put(`${API}/survey`, data);
  } catch (error) {
    throw error;
  }
};

export const getSettingsCode = async (id, user) => {
  try {
    return await axios.get(`${API}/survey/settings/${id}`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const getBillingPortal = async (user) => {
  try {
    return await axios.get(`${API}/billing-portal`, axiosConfig(user));
  } catch (error) {
    throw error;
  }
};

export const changeSubscriptionPlan = async (user, type) => {
  try {
    return await axios.get(
      `${API}/subscription/change/` + type,
      axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const sendInquiry = async (inquiry) => {
  try {
    return await axios.post(`${API}/inquiry`, inquiry);
  } catch (error) {
    throw error;
  }
};

export const updateHealthConcerns = async (healthConcerns, user) => {
  try {
    return await axios.post(
        `${API}/clinic/health-concerns`,
        healthConcerns,
        axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const updateLifeImpact = async (lifeImpact, user) => {
  try {
    return await axios.post(
        `${API}/clinic/life-impact`,
        lifeImpact,
        axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};

export const updateOverallHealth = async (overallHealth, user) => {
  try {
    return await axios.post(
        `${API}/clinic/overall-health`,
        overallHealth,
        axiosConfig(user)
    );
  } catch (error) {
    throw error;
  }
};


export const createPatient = async (patientData, user) => {
  try {
    return await axios.post(
        `${API}/patients/create`,
        patientData,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};

export const getSurveyLinkForLanguageAndType = async (formData, user) => {
  try {
    return await axios.post(
        `${API}/patient/survey/link`,
        formData,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};


// PRACTICE HUB INTEGRATION
export const practiceHubSetApiKey = async (payload, user) => {
  try {
    return await axios.post(
        `${API}/practicehub/key`,
        payload,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};
export const practiceHubUpdateSettings = async (payload, user) => {
  try {
    return await axios.post(
        `${API}/practicehub/settings`,
        payload,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};
export const practiceHubSyncUsers = async (user) => {
  try {
    return await axios.get(
        `${API}/practicehub/users/sync`,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};

export const practiceHubToggleActive = async (user) => {
  try {
    return await axios.patch(
        `${API}/practicehub/settings/active`,
        {},
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};


export const setSurveyDefaultsForPracticeHubPatient = async (formData, patient, user) => {
  try {
    return await axios.post(
        `${API}/patient/${patient?.id}/practicehub/settings`,
        formData,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};


export const validateSurveyPersonalizationData = async (formData, user) => {
  try {
    return await axios.post(
        `${API}/settings/survey/personalization/validate`,
        formData,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};

export const updateSurveyPersonalization = async (formData, user) => {
  try {
    return await axios.post(
        `${API}/settings/survey/personalization`,
        formData,
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};

export const restoreHealthMetric = async (order, user) => {
  try {
    return await axios.patch(
        `${API}/settings/survey/metrics/order/${order}/restore`,
        {},
        axiosConfig(user)
    );
  } catch (error) {
    return error;
  }
};



export const getPatientNameById = async (id) => {
  try {
    return await axios.get(
        `${API}/patients/${id}/name`,
        {},
    );
  } catch (error) {
    return error;
  }
};




