import PersonalizationCardImage from "./components/PersonalizationCardImage";
import PersonalizationInput from "./components/PersonalizationInput";
import PersonalizationTextarea from "./components/PersonalizationTextarea";
import PersonalizationCardTabPicker from "./components/PersonalizationCardTabPicker";
import {useState} from "react";

export default function PersonalizationStresses({getError,stressesFormData,setStressesFormData}) {

    const tabs = [
        {label:'Physical Stresses', value:'physical'},
        {label:'Chemical Stresses', value:'chemical'},
        {label:'Emotional Stresses', value:'emotional'},
    ];
    const [activeTab,setActiveTab] = useState(tabs[0]);


    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap:'20px'
    }}>
        <PersonalizationCardTabPicker
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />


        {
            activeTab?.value === tabs?.[0]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap:'20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/stresses_physical.png'} alt={'Welcome Screen'} />

                <PersonalizationInput
                    title={'Title'}
                    value={stressesFormData?.physicalTitle?.toUpperCase()}
                    setValue={(value) => setStressesFormData({physicalTitle: value?.toUpperCase()})}
                    errorMessage={getError('stresses','physicalTitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={stressesFormData?.physicalQuestion}
                    setValue={(value) => setStressesFormData({physicalQuestion: value})}
                    errorMessage={getError('stresses','physicalQuestion')}
                />

                <PersonalizationInput
                    title={'Answer 1'}
                    value={stressesFormData?.physicalAnswer1}
                    setValue={(value) => setStressesFormData({physicalAnswer1: value})}
                    errorMessage={getError('stresses','physicalAnswer1')}
                />
                <PersonalizationInput
                    title={'Answer 2'}
                    value={stressesFormData?.physicalAnswer2}
                    setValue={(value) => setStressesFormData({physicalAnswer2: value})}
                    errorMessage={getError('stresses','physicalAnswer2')}
                />
                <PersonalizationInput
                    title={'Answer 3'}
                    value={stressesFormData?.physicalAnswer3}
                    setValue={(value) => setStressesFormData({physicalAnswer3: value})}
                    errorMessage={getError('stresses','physicalAnswer3')}
                />
                <PersonalizationInput
                    title={'Answer 4'}
                    value={stressesFormData?.physicalAnswer4}
                    setValue={(value) => setStressesFormData({physicalAnswer4: value})}
                    errorMessage={getError('stresses','physicalAnswer4')}
                />
                <PersonalizationInput
                    title={'Answer 5'}
                    value={stressesFormData?.physicalAnswer5}
                    setValue={(value) => setStressesFormData({physicalAnswer5: value})}
                    errorMessage={getError('stresses','physicalAnswer5')}
                />
            </div>
        }
        {
            activeTab?.value === tabs?.[1]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap:'20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/stresses_chemical.png'} alt={'Welcome Screen'} />

                <PersonalizationInput
                    title={'Title'}
                    value={stressesFormData?.chemicalTitle?.toUpperCase()}
                    setValue={(value) => setStressesFormData({chemicalTitle: value?.toUpperCase()})}
                    errorMessage={getError('stresses','chemicalTitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={stressesFormData?.chemicalQuestion}
                    setValue={(value) => setStressesFormData({chemicalQuestion: value})}
                    errorMessage={getError('stresses','chemicalQuestion')}
                />

                <PersonalizationInput
                    title={'Answer 1'}
                    value={stressesFormData?.chemicalAnswer1}
                    setValue={(value) => setStressesFormData({chemicalAnswer1: value})}
                    errorMessage={getError('stresses','chemicalAnswer1')}
                />
                <PersonalizationInput
                    title={'Answer 2'}
                    value={stressesFormData?.chemicalAnswer2}
                    setValue={(value) => setStressesFormData({chemicalAnswer2: value})}
                    errorMessage={getError('stresses','chemicalAnswer2')}
                />
                <PersonalizationInput
                    title={'Answer 3'}
                    value={stressesFormData?.chemicalAnswer3}
                    setValue={(value) => setStressesFormData({chemicalAnswer3: value})}
                    errorMessage={getError('stresses','chemicalAnswer3')}
                />
                <PersonalizationInput
                    title={'Answer 4'}
                    value={stressesFormData?.chemicalAnswer4}
                    setValue={(value) => setStressesFormData({chemicalAnswer4: value})}
                    errorMessage={getError('stresses','chemicalAnswer4')}
                />
                <PersonalizationInput
                    title={'Answer 5'}
                    value={stressesFormData?.chemicalAnswer5}
                    setValue={(value) => setStressesFormData({chemicalAnswer5: value})}
                    errorMessage={getError('stresses','chemicalAnswer5')}
                />
            </div>
        }
        {
            activeTab?.value === tabs?.[2]?.value && <div style={{
                display: "flex",
                flexDirection: "column",
                gap:'20px'
            }}>
                <PersonalizationCardImage src={'/assets/survey_personalization_screens/stresses_emotional.png'} alt={'Welcome Screen'} />

                <PersonalizationInput
                    title={'Title'}
                    value={stressesFormData?.emotionalTitle?.toUpperCase()}
                    setValue={(value) => setStressesFormData({emotionalTitle: value?.toUpperCase()})}
                    errorMessage={getError('stresses','emotionalTitle')}
                />

                <PersonalizationTextarea
                    title={'Question/Statement'}
                    value={stressesFormData?.emotionalQuestion}
                    setValue={(value) => setStressesFormData({emotionalQuestion: value})}
                    errorMessage={getError('stresses','emotionalQuestion')}
                />

                <PersonalizationInput
                    title={'Answer 1'}
                    value={stressesFormData?.emotionalAnswer1}
                    setValue={(value) => setStressesFormData({emotionalAnswer1: value})}
                    errorMessage={getError('stresses','emotionalAnswer1')}
                />
                <PersonalizationInput
                    title={'Answer 2'}
                    value={stressesFormData?.emotionalAnswer2}
                    setValue={(value) => setStressesFormData({emotionalAnswer2: value})}
                    errorMessage={getError('stresses','emotionalAnswer2')}
                />
                <PersonalizationInput
                    title={'Answer 3'}
                    value={stressesFormData?.emotionalAnswer3}
                    setValue={(value) => setStressesFormData({emotionalAnswer3: value})}
                    errorMessage={getError('stresses','emotionalAnswer3')}
                />
                <PersonalizationInput
                    title={'Answer 4'}
                    value={stressesFormData?.emotionalAnswer4}
                    setValue={(value) => setStressesFormData({emotionalAnswer4: value})}
                    errorMessage={getError('stresses','emotionalAnswer4')}
                />
                <PersonalizationInput
                    title={'Answer 5'}
                    value={stressesFormData?.emotionalAnswer5}
                    setValue={(value) => setStressesFormData({emotionalAnswer5: value})}
                    errorMessage={getError('stresses','emotionalAnswer5')}
                />
            </div>
        }

    </div>
}
