import {useSelector} from "react-redux";
import {useEffect} from "react";
import Loader from "../../Loader/Loader";

export default function SurveySettingsPersonalizationScreens({
                                                                 isBlocked = false,
                                                                isUpdatingSettings = false,
                                                                 showGlobalSnackbar,
                                                                 activeScreen,

                                                                 languages,
                                                                 setActiveLanguage,
                                                                 activeLanguage,

                                                                 allSurveySettingsFormData,
                                                                 setAllSurveySettingsFormData,

                                                                 surveySettingsFormData,
                                                                 setSurveySettingsFormData,

                                                                 healthConcernsFormData,
                                                                 setHealthConcernsFormData,

                                                                 lifeImpactsFormData,
                                                                 setLifeImpactsFormData,

                                                                 overallHealths,
                                                                 setOverallHealths,

                                                                 stressesFormData,
                                                                 setStressesFormData,

                                                                 defaultBodyMetrics,

                                                                 getError,
                                                                 reviewLink,
                                                                 setReviewLink,
}) {
    const user = useSelector((state) => state.user);

    const ActiveScreenComponent = activeScreen?.screen;

    return <div style={{paddingBottom:'80px'}}>
        {(activeScreen && activeScreen?.screen) && <div
            style={{
                padding: "40px",
                borderRadius: "27px",
                backgroundColor: "rgba(244, 244, 243, 1)",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {
                isUpdatingSettings && <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgb(97, 132, 157, 0.3)",
                    zIndex: "2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 !important"
                }}>
                    <Loader/>
                </div>
            }

            {
                isBlocked && <div style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(120, 120, 130, 0.3)",
                    zIndex: "2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 !important"
                }}>
                </div>
            }

            <ActiveScreenComponent
                    {...activeScreen?.screenData}
                    user={user}

                    showGlobalSnackbar={showGlobalSnackbar}

                    languages={languages}
                    activeLanguage={activeLanguage}
                    setActiveLanguage={setActiveLanguage}

                    allSurveySettingsFormData={allSurveySettingsFormData}
                    setAllSurveySettingsFormData={setAllSurveySettingsFormData}

                    surveySettingsFormData={surveySettingsFormData}
                    setSurveySettingsFormData={setSurveySettingsFormData}

                    healthConcernsFormData={healthConcernsFormData}
                    setHealthConcernsFormData={setHealthConcernsFormData}

                    lifeImpactsFormData={lifeImpactsFormData}
                    setLifeImpactsFormData={setLifeImpactsFormData}

                    overallHealths={overallHealths}
                    setOverallHealths={setOverallHealths}

                    stressesFormData={stressesFormData}
                    setStressesFormData={setStressesFormData}

                    defaultBodyMetrics={defaultBodyMetrics}

                    getError={getError}

                    reviewLink={reviewLink}
                    setReviewLink={setReviewLink}
            />
        </div>}
    </div>
}
